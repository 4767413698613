<template>
  <div class="accent">
    <v-lazy
      v-model="isActive"
      :options="{
        threshold: 0.5,
      }"
    >
      <v-fab-transition>
        <v-container>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
              <p
                class="white--text caption pt-12"
                style="
                  border-bottom: 1px solid black;
                  padding-bottom: 5px;
                  width: max-content;
                  border-color: #a67d58;
                "
              >
                {{ $t("about.s2.OURTEAM") }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <div class="card">
                <v-card
                  dark
                  :loading="loading"
                  class="mx-auto my-12 pa-2 pt-6 rounded-r-xl accent"
                  max-width="374"
                >
                  <v-icon color="white" class="px-3" x-large
                    >mdi-account</v-icon
                  >
                  <v-card-title class="subtitle-1">{{
                    $t("about.s2.CIVILSTAFF")
                  }}</v-card-title>

                  <v-card-text>
                    <div class="caption">
                      {{ $t("about.s2.CIVILSTAFF_desc") }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <!-- <v-col cols="12" lg="3" md="3" sm="6" xs="12">
              <div class="card">
                <v-card
                  dark
                  :loading="loading"
                  class="mx-auto my-12 pa-2 pt-6 rounded-r-xl accent"
                  max-width="374"
                >
                  <v-icon class="px-3" color="white" x-large>mdi-format-color-fill</v-icon>

                  <v-card-title class="subtitle-1">{{
                    $t("about.s2.ARCHITECTURALSTAFF")
                  }}</v-card-title>

                  <v-card-text>
                    <div class="caption">
                      {{ $t("about.s2.ARCHITECTURALSTAFF_desc") }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col> -->
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <div class="card">
                <v-card
                  dark
                  :loading="loading"
                  class="mx-auto my-12 pa-2 pt-6 rounded-l-xl accent"
                  max-width="374"
                >
                  <v-icon class="px-3" x-large color="white"
                    >mdi-face-agent
                  </v-icon>

                  <v-card-title class="subtitle-1">{{
                    $t("about.s2.TECHNICALSTAFF")
                  }}</v-card-title>

                  <v-card-text>
                    <div class="caption">
                      {{ $t("about.s2.TECHNICALSTAFF_desc") }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <div class="card">
                <v-card
                  dark
                  :loading="loading"
                  class="mx-auto my-12 pa-2 pt-6 rounded-l-xl accent"
                  max-width="374"
                >
                  <v-icon class="px-3" x-large color="white"
                    >mdi-database
                  </v-icon>

                  <v-card-title class="subtitle-1">{{
                    $t("about.s2.ADMINISTRATIVESTAFF")
                  }}</v-card-title>

                  <v-card-text>
                    <div class="caption">
                      {{ $t("about.s2.ADMINISTRATIVESTAFF_desc") }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-fab-transition>
    </v-lazy>
  </div>
</template>

<script>
export default {
  name: "Install",
  computed: {
    plugins() {
      return JSON.parse(JSON.stringify(this.$t("home.high")));
    },
    data: () => ({
      isActive: false,
    }),

    methods: {},
  },
};
</script>

<style lang="scss" scoped>
.section-header .v-divider {
  border-width: 1px;
  width: 150px;
  background-color: #ffca28;
}
</style>
